import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Home from './pages/Home';
import AdminPanel from './pages/Admin';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import DropdownMenu from './components/DropdownMenu';
import PasswordPage from './components/PasswordPage'; // Importar el nuevo componente
import './App.css';
import { FaUserCircle } from 'react-icons/fa';
import axios from 'axios';

const AnimatedRoutes = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          await axios.get('/api/auth/verify-token', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Token inválido o expirado:', error);
          handleLogout();
        }
      }
    };

    checkToken();
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const isPasswordAuthenticated = () => {
    return localStorage.getItem('sitePassword') === process.env.REACT_APP_SITE_PASSWORD;
  };

  return (
    <>
      <header className="header">
        {isAuthenticated && (
          <div className="header-content">
            <h1 className="admin-title">Administrador</h1>
            <div className="menu-container">
              <button className="menu-button" onClick={toggleMenu}>
                <FaUserCircle />
              </button>
              {menuOpen && <DropdownMenu onLogout={handleLogout} closeMenu={() => setMenuOpen(false)} />}
            </div>
          </div>
        )}
      </header>
      <SwitchTransition>
        <CSSTransition
          key={location.key}
          classNames="page"
          timeout={300}
        >
          <Routes location={location}>
            <Route path="/password" element={<PasswordPage />} />
            <Route path="/login" element={isAuthenticated ? <Navigate to="/admin" /> : <Login onLogin={handleLogin} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/admin" element={isAuthenticated ? <AdminPanel /> : <Navigate to="/login" />} />
            <Route path="/" element={isPasswordAuthenticated() ? <Home /> : <Navigate to="/password" />} />
            <Route path="*" element={<Navigate to="/password" />} />
          </Routes>
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};

function App() {
  return (
    <Router>
      <div className="app-container">
        <main className="main-content">
          <AnimatedRoutes />
        </main>
      </div>
    </Router>
  );
}

export default App;

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './PasswordPage.css';

const PasswordPage = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === process.env.REACT_APP_SITE_PASSWORD) {
      localStorage.setItem('sitePassword', password);
      navigate('/');
    } else {
      setError('Contraseña incorrecta');
    }
  };

  return (
    <div className="password-page-container">
      <form onSubmit={handleSubmit} className="password-form">
        <h2>Ingrese la contraseña</h2>
        {error && <div className="error">{error}</div>}
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" className="password-button">Ingresar</button>
        <div className="register-link">
          <Link to="/register">¿No tienes cuenta? Regístrate aquí</Link>
        </div>
      </form>
    </div>
  );
};

export default PasswordPage;

// components/ToggleSwitch.js
import React from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = ({ isNutrition, setIsNutrition }) => {
  return (
    <div className="toggle-container">
      <button
        className={`toggle-button ${!isNutrition ? 'active' : ''}`}
        onClick={() => setIsNutrition(false)}
      >
        Ingredientes
      </button>
      <button
        className={`toggle-button ${isNutrition ? 'active' : ''}`}
        onClick={() => setIsNutrition(true)}
      >
        Alimentos
      </button>
    </div>
  );
};

export default ToggleSwitch;

// pages/Home.js
import React, { useState } from 'react';
import IngredientHighlighter from '../components/IngredientHighlighter';
import NutritionAnalyzer from '../components/NutritionAnalyzer';
import ToggleSwitch from '../components/ToggleSwitch';
import './Home.css';

const Home = () => {
  const [isNutrition, setIsNutrition] = useState(false);

  return (
    <div className="home-container">
      <ToggleSwitch isNutrition={isNutrition} setIsNutrition={setIsNutrition} />
      {isNutrition ? <NutritionAnalyzer /> : <IngredientHighlighter />}
    </div>
  );
};

export default Home;

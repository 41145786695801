import React, { useState } from 'react';
import axios from 'axios';
import ContentEditable from 'react-contenteditable';
import './IngredientHighlighter.css'; // Asegúrate de importar los mismos estilos

const NutritionAnalyzer = () => {
  const [nutrition, setNutrition] = useState('');
  const [highlightedText, setHighlightedText] = useState('');
  const [harmfulFoods, setHarmfulFoods] = useState([]);
  const [foodList, setFoodList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const handleChange = (evt) => {
    setNutrition(evt.target.value);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsLocked(true);
    try {
      const cleanedInput = cleanInput(nutrition);
      const foodsArray = splitIngredients(cleanedInput);

      const response = await axios.post('https://ingredientanalyzer.onrender.com/api/foods/analyze', { foods: foodsArray });

      setHarmfulFoods(response.data.harmfulFoods);
      setFoodList(foodsArray);
      setHighlightedText(highlightText(cleanedInput, response.data.harmfulFoods, foodsArray));
    } catch (error) {
      console.error('Error al enviar la información nutricional:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setNutrition('');
    setHighlightedText('');
    setHarmfulFoods([]);
    setFoodList([]);
    setIsLocked(false);
  };

  const cleanInput = (input) => {
    return input.replace(/\s+/g, ' ').trim();
  };

  const splitIngredients = (input) => {
    return input.split(/,\s*/).map(item => item.trim().toLowerCase());
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const highlightText = (text, harmfulWords, foodList) => {
    if (!foodList.length) return text;

    let highlightedText = text;

    const harmfulWordsLower = harmfulWords.map(food => ({
      ...food,
      name: food.name.toLowerCase()
    }));

    harmfulWordsLower.forEach(food => {
      const regex = new RegExp(`(${escapeRegExp(food.name)})`, 'gi');
      const className = food.harmfulLevel === 'dañino' ? 'harmful' : food.harmfulLevel === 'medio dañino' ? 'medium-harmful' : 'non-harmful';
      highlightedText = highlightedText.replace(regex, `<span class="${className}">$1</span>`);
    });

    foodList.forEach(word => {
      if (!harmfulWordsLower.some(harmful => harmful.name === word.toLowerCase())) {
        const regex = new RegExp(`(${escapeRegExp(word)})`, 'gi');
        highlightedText = highlightedText.replace(regex, `<span class="non-harmful">$1</span>`);
      }
    });

    return highlightedText;
  };

  return (
    <div className="nutrition-analyzer">
      <h1 className="home-title">ANÁLISIS DE ALIMENTOS</h1>
      <p className="home-description">
        Revisa los ingredientes de los alimentos para detectar posibles ingredientes dañinos o alérgenos. Por favor, encuentra la lista de ingredientes del producto que te gustaría revisar en internet, cópiala e insértala aquí.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="editable-container">
          <ContentEditable
            className={`content-editable ${isLocked ? 'locked' : ''}`}
            html={nutrition}
            onChange={handleChange}
            onPaste={handlePaste}
            disabled={isLocked}
          />
          {nutrition === '' && !isLocked && (
            <div className="placeholder">alimento1, alimento2, alimento3</div>
          )}
        </div>
        <div className="button-group">
          <button type="submit" className="button submit-button" disabled={isLocked}>Revisar</button>
          <button type="button" className="button reset-button" onClick={handleReset}>Reiniciar</button>
        </div>
      </form>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="result-container" dangerouslySetInnerHTML={{ __html: highlightedText }} />
      )}
      {foodList.length > 0 && (
        <div className="all-ingredients-list">
          <h3>Detalles de Alimentos:</h3>
          <ul>
            {harmfulFoods.map((food, index) => (
              <li key={index}>
                <strong>{food.name.toLowerCase()}:</strong> {food.description}
              </li>
            ))}
            {foodList.filter(food => !harmfulFoods.map(f => f.name.toLowerCase()).includes(food.toLowerCase())).map((food, index) => (
              <li key={index}>
                <strong>{food.toLowerCase()}:</strong> no dañino
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="help-box">
        <h4>Formato Admitido</h4>
        <p>Por favor, ingresa los alimentos separados por comas.</p>
        <p>Ejemplo: alimento1, alimento2, alimento3</p>
      </div>
    </div>
  );
};

export default NutritionAnalyzer;

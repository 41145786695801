import React from 'react';
import { Link } from 'react-router-dom';
import './DropdownMenu.css';

const DropdownMenu = ({ onLogout, closeMenu }) => {
  const handleMenuClick = () => {
    closeMenu();
  };

  return (
    <div className="dropdown-menu">
      <Link to="/" onClick={handleMenuClick}>Página Principal</Link>
      <Link to="/admin" onClick={handleMenuClick}>Página de Admin</Link>
      <button onClick={() => { onLogout(); handleMenuClick(); }}>Cerrar sesión</button>
    </div>
  );
};

export default DropdownMenu;

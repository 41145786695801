import React, { useState, useEffect } from 'react';
import api from './api'; // Importa la configuración de Axios
import { FaPencilAlt } from 'react-icons/fa';
import './AdminPanel.css';

const AdminPanel = () => {
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    _id: null,
    name: '',
    description: '',
    harmfulLevel: 'no dañino',
  });
  const [dataType, setDataType] = useState('ingredients');

  useEffect(() => {
    fetchData();
  }, [dataType]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/${dataType}`);
      const responseData = Array.isArray(response.data) ? response.data : [];
      setData(responseData);
    } catch (error) {
      console.error(`Error fetching ${dataType}:`, error);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddClick = () => {
    setIsEditing(false);
    setCurrentItem({
      _id: null,
      name: '',
      description: '',
      harmfulLevel: 'no dañino',
    });
    setModalOpen(true);
  };

  const handleEditClick = (item) => {
    setIsEditing(true);
    setCurrentItem({
      _id: item._id,
      name: item.name,
      description: item.description,
      harmfulLevel: item.harmfulLevel,
    });
    setModalOpen(true);
  };

  const handleDeleteClick = async (id) => {
    const confirmDelete = window.confirm(`¿Estás seguro de que deseas eliminar este ${dataType === 'ingredients' ? 'ingrediente' : 'alimento'}?`);
    if (confirmDelete) {
      try {
        await api.delete(`/${dataType}/${id}`);
        fetchData();
        setModalOpen(false);
      } catch (error) {
        console.error(`Error deleting ${dataType.slice(0, -1)}:`, error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentItem({ ...currentItem, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const itemData = {
        name: currentItem.name,
        description: currentItem.description,
        harmfulLevel: currentItem.harmfulLevel,
      };

      if (isEditing) {
        await api.put(`/${dataType}/${currentItem._id}`, itemData);
      } else {
        await api.post(`/${dataType}`, itemData);
      }
      setModalOpen(false);
      fetchData();
    } catch (error) {
      console.error(`Error saving ${dataType.slice(0, -1)}:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleChange = (value) => {
    setDataType(value);
  };

  return (
    <div className="admin-container">
      <div className="header-container">
        <h1 className="admin-title">Panel de Administración</h1>
        <div className="toggle-container">
          <div className="toggle-switch" onClick={() => handleToggleChange(dataType === 'ingredients' ? 'foods' : 'ingredients')}>
            <input
              type="radio"
              name="dataType"
              value="ingredients"
              checked={dataType === 'ingredients'}
              readOnly
            />
            <input
              type="radio"
              name="dataType"
              value="foods"
              checked={dataType === 'foods'}
              readOnly
            />
            <div className="slider"></div>
            <div className={`toggle-option ${dataType === 'ingredients' ? 'active' : 'inactive'}`}>
              Ingredientes
            </div>
            <div className={`toggle-option ${dataType === 'foods' ? 'active' : 'inactive'}`}>
              Alimentos
            </div>
          </div>
        </div>
        <button className="add-button" onClick={handleAddClick}>Agregar {dataType === 'ingredients' ? 'Ingrediente' : 'Alimento'}</button>
      </div>
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <table className="ingredients-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Nivel de Peligrosidad</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item._id}>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>
                  <div
                    className="harmful-level"
                    style={{
                      backgroundColor:
                        item.harmfulLevel === 'dañino'
                          ? 'red'
                          : item.harmfulLevel === 'medio dañino'
                          ? 'orange'
                          : 'green',
                    }}
                  ></div>
                </td>
                <td>
                  <div className="action-icon">
                    <FaPencilAlt className="edit-icon" onClick={() => handleEditClick(item)} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>{isEditing ? `Editar ${dataType === 'ingredients' ? 'Ingrediente' : 'Alimento'}` : `Agregar ${dataType === 'ingredients' ? 'Ingrediente' : 'Alimento'}`}</h2>
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Nombre</label>
              <input
                type="text"
                id="name"
                name="name"
                value={currentItem.name}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="description">Descripción</label>
              <textarea
                id="description"
                name="description"
                value={currentItem.description}
                onChange={handleInputChange}
                required
              ></textarea>
              <label htmlFor="harmfulLevel">Nivel de Peligrosidad</label>
              <select
                id="harmfulLevel"
                name="harmfulLevel"
                value={currentItem.harmfulLevel}
                onChange={handleInputChange}
                required
              >
                <option value="no dañino">No Dañino</option>
                <option value="medio dañino">Medio Dañino</option>
                <option value="dañino">Dañino</option>
              </select>
              <div className="modal-buttons">
                <button type="submit" className="submit-button">Guardar</button>
                {isEditing && (
                  <button
                    type="button"
                    className="delete-button-modal"
                    onClick={() => handleDeleteClick(currentItem._id)}
                  >
                    Eliminar
                  </button>
                )}
                <button type="button" className="cancel-button" onClick={() => setModalOpen(false)}>
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;

import React, { useState } from 'react';
import axios from 'axios';
import ContentEditable from 'react-contenteditable';
import './IngredientHighlighter.css'; // Asegúrate de importar los estilos

const IngredientHighlighter = () => {
  const [ingredients, setIngredients] = useState('');
  const [highlightedText, setHighlightedText] = useState('');
  const [harmfulIngredients, setHarmfulIngredients] = useState([]);
  const [ingredientList, setIngredientList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const handleChange = (evt) => {
    setIngredients(evt.target.value);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsLocked(true);
    try {
      const cleanedInput = cleanInput(ingredients);
      const ingredientsArray = splitIngredients(cleanedInput);

      const response = await axios.post('https://ingredientanalyzer.onrender.com/api/ingredients/analyze', { ingredients: ingredientsArray });
      console.log('Ingredientes analizados:', response.data);

      setHarmfulIngredients(response.data.harmfulIngredients);
      setIngredientList(ingredientsArray);
      setHighlightedText(highlightText(cleanedInput, response.data.harmfulIngredients, ingredientsArray));
    } catch (error) {
      console.error('Error al enviar los ingredientes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setIngredients('');
    setHighlightedText('');
    setHarmfulIngredients([]);
    setIngredientList([]);
    setIsLocked(false);
  };

  const cleanInput = (input) => {
    return input.replace(/\s+/g, ' ').trim();
  };

  const splitIngredients = (input) => {
    return input.split(/,\s*/).map(item => item.trim().toLowerCase());
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const highlightText = (text, harmfulWords, ingredientList) => {
    if (!ingredientList.length) return text;

    let highlightedText = text;

    const harmfulWordsLower = harmfulWords.map(ingredient => ({
      ...ingredient,
      name: ingredient.name.toLowerCase()
    }));

    harmfulWordsLower.forEach(ingredient => {
      const regex = new RegExp(`(${escapeRegExp(ingredient.name)})`, 'gi');
      const className = ingredient.harmfulLevel === 'dañino' ? 'harmful' : ingredient.harmfulLevel === 'medio dañino' ? 'medium-harmful' : 'non-harmful';
      highlightedText = highlightedText.replace(regex, `<span class="${className}">$1</span>`);
    });

    ingredientList.forEach(word => {
      if (!harmfulWordsLower.some(harmful => harmful.name === word.toLowerCase())) {
        const regex = new RegExp(`(${escapeRegExp(word)})`, 'gi');
        highlightedText = highlightedText.replace(regex, `<span class="non-harmful">$1</span>`);
      }
    });

    return highlightedText;
  };

  return (
    <div className="ingredient-highlighter">
      <h1 className="home-title">PIEL, PELO, MAQUILLAJE</h1>
      <p className="home-description">
        Si tienes piel propensa al acné, necesitas revisar los productos de cuidado de la piel, cuidado del cabello y maquillaje para ver si contienen ingredientes comedogénicos (que obstruyen los poros). Por favor, encuentra la lista de ingredientes del producto que te gustaría revisar en internet, cópiala e insértala aquí.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="editable-container">
          <ContentEditable
            className={`content-editable ${isLocked ? 'locked' : ''}`}
            html={ingredients}
            onChange={handleChange}
            onPaste={handlePaste}
            disabled={isLocked}
          />
          {ingredients === '' && !isLocked && (
            <div className="placeholder">ingrediente1, ingrediente2, ingrediente3</div>
          )}
        </div>
        <div className="button-group">
          <button type="submit" className="button submit-button" disabled={isLocked}>Revisar</button>
          <button type="button" className="button reset-button" onClick={handleReset}>Reiniciar</button>
        </div>
      </form>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="result-container" dangerouslySetInnerHTML={{ __html: highlightedText }} />
      )}
      {ingredientList.length > 0 && (
        <div className="all-ingredients-list">
          <h3>Detalles de Ingredientes:</h3>
          <ul>
            {harmfulIngredients.map((ingredient, index) => (
              <li key={index}>
                <strong>{ingredient.name.toLowerCase()}:</strong> {ingredient.description}
              </li>
            ))}
            {ingredientList.filter(ingredient => !harmfulIngredients.map(i => i.name.toLowerCase()).includes(ingredient.toLowerCase())).map((ingredient, index) => (
              <li key={index}>
                <strong>{ingredient.toLowerCase()}:</strong> no dañino
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="help-box">
        <h4>Formato Admitido</h4>
        <p>Por favor, ingresa los ingredientes separados por comas.</p>
        <p>Ejemplo: ingrediente1, ingrediente2, ingrediente3</p>
      </div>
    </div>
  );
};

export default IngredientHighlighter;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Auth.css';

const Register = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Cargar el script de Klaviyo
    const script = document.createElement('script');
    script.src = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=XMdTX7";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const handleButtonClick = () => {
      setTimeout(() => {
        navigate('/password');
      }, 5000); // Redirigir después de 10 segundos
    };

    // Asegúrate de que el DOM esté completamente cargado antes de buscar el botón
    const intervalId = setInterval(() => {
      const button = document.querySelector('.klaviyo-form-YmZqXW button[type="button"]');
      if (button) {
        button.addEventListener('click', handleButtonClick);
        clearInterval(intervalId); // Limpiar el intervalo una vez que se encuentra el botón
      }
    }, 500); // Verificar cada 500ms

    return () => {
      clearInterval(intervalId);
    };
  }, [navigate]);

  return (
    <div className="auth-container">
      <h2>Registrarse</h2>
      <div className="klaviyo-form-YmZqXW"></div> {/* Contenedor del formulario de Klaviyo */}
    </div>
  );
};

export default Register;
